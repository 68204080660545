@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import "vars";
body {
    font-family: var(--font-primary);/*update this to site font*/
    font-size: 16px;
    color: var(--color-primary); /* update this to site body colour */
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

hr{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--blue);
    margin: 1em 0;
    padding: 0;
}


.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: var(--white);
    background-color: var(--blue);
    border: none;
    box-sizing: border-box;
    transition: all 0.3s ease;
    font-weight: 700;
    min-width: 170px;
    border-radius: 7px;
    font-size: clamp(14px, 2vw, 16px);
    line-height: 40px;
}
.button:hover {
    transform: scale(1.2);
}

.button-secondary {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: var(--blue);
    background-color: var(--white);
    border: 2px solid var(--blue);
    box-sizing: border-box;
    transition: all 0.3s ease;
    border-radius: 20px;
}
.button-secondary:hover {
    color: var(--white);
    background-color: var(--blue);
    transform: scale(1.1);
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}
img.full-width{
    width:100%;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
.text-blue{
    color: var(--blue);
}
.text-yellow{
    color: var(--yellow);
}
.text-black{
    color: var(--black);
}
.text-white{
    color: var(--white);
}
.text-title{
    font-size:clamp(18px, 4vw, 30px);
    line-height: clamp(20px, 4.5vw, 46px);;
    font-weight: 600;
}
.text-medium{
    font-weight:500;
}
.text-semibold{
    font-weight:600;
}
.two-col{
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap:1rem;
    list-style: none;
    padding: 0;
    @media screen and (max-width: 768px) {
        grid-template-columns:1fr;
    }
}