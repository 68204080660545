
:root {
    //color
    --black: #4C4D4F;
    --white: #FAFBFC;
    --yellow: #FBB033;
    --blue: #021D49;
    //font style
    --font-primary: 'Roboto', sans-serif;

    //font color
    --color-primary: #4C4D4F;


}